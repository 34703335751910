import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_APIADMINURL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');

Vue.config.productionTip = false;

Vue.directive('select', {
    twoWay: true,
    bind: function (el, binding, vnode) {
        $(el).select2().on("select2:select", (e) => {
            // v-model looks for
            //  - an event named "change"
            //  - a value with property path "$event.target.value"
            el.dispatchEvent(new Event('change', {target: e.target}));
        });
    },
    componentUpdated: function (el, me) {
        // update the selection if the value is changed externally
        $(el).trigger("change");
    }
});


Vue.mixin({
    created: function () {
        this.ws_Connection_close();
        this.dashboard_ws_Connection_close();
    },
    methods: {
        ws_Connection_close() {
            var currentRoute = this.$router.history.current.name;
            var wsStatus = this.$store.getters.getWsConnectOpenStatus;
            if (currentRoute !== 'ServerLists' && currentRoute !== 'IpLists' && wsStatus === true) {
                var wsOpenConnections = this.$store.getters.getWsOpenConnections;
                for (var i = 0; i < Object.keys(wsOpenConnections).length; i++) {
                    wsOpenConnections[i].close();
                }
                ;
                this.$store.dispatch('setWsConnectOpenStatus', false);
                this.$store.dispatch('setWsOpenConnections', {});
                this.$store.dispatch('setServerWSData', {});
            }
        },
        dashboard_ws_Connection_close() {
            var currentRoute = this.$router.history.current.name;
            var dsWsStatus = this.$store.getters.getDsWsConnectionStatus;
            if (currentRoute !== 'Home' && dsWsStatus === true) {
                var wsOpenConnections = this.$store.getters.getDsWsConnections;
                for (var i = 0; i < Object.keys(wsOpenConnections).length; i++) {
                    wsOpenConnections[i].close();
                }
                ;
                this.$store.dispatch('setDsWsConnectionStatus', false);
                this.$store.dispatch('setDsWsConnection', {});
            }
        },
        com_DecryptString(string, key = '') {
            if (key == '') {
                key = process.env.VUE_APP_ENCRYPTIONKEY;
            }

            let str = atob(string);
            var output = "";
            for (var i = 0; i < str.length;) {
                for (var j = 0; (j < key.length && i < str.length); j++, i++) {
                    output += String.fromCharCode(str[i].charCodeAt(0) ^ key[j].charCodeAt(0));
                }
            }
            return output;
        },

        com_EncryptString(string, key = '') {
            if (key == '') {
                key = process.env.VUE_APP_ENCRYPTIONKEY;
            }

            let str = string.toString();
            var output = "";
            for (var i = 0; i < str.length;) {
                for (var j = 0; (j < key.length && i < str.length); j++, i++) {
                    output += String.fromCharCode(str[i].charCodeAt(0) ^ key[j].charCodeAt(0));
                }
            }
            return btoa(output);
        },

        com_setLoginData(param) {
            let rootObject = this;
            if (param.jwt_token) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + param.jwt_token;
                axios.get('profile').then(function (response) {
                    localStorage.setItem("jwt_token", param.jwt_token);
                    rootObject.$store.dispatch('changLoginStatus', true);
                    rootObject.$store.dispatch('setUser', response.data.user);
                    rootObject.com_CheckVisitActivityAndTokenStatus();
                    if (param.redirect_url) {
                        rootObject.$router.push(param.redirect_url);
                    }
                })
                    .catch(function (error) {
                        let alertmessage = "";
                        for (var prop in error.response.data) {
                            alertmessage += ' ' + error.response.data[prop]
                        }
                        localStorage.removeItem("jwt_token");
                        rootObject.$store.dispatch('setUser', null);
                        rootObject.$store.dispatch('changLoginStatus', false);
                        rootObject.com_CheckVisitActivityAndTokenStatus(false);
                        console.log(alertmessage);
                        if (param.redirect_url) {
                            rootObject.$router.push(param.redirect_url);
                        } else {
                            rootObject.$router.push('logout');
                        }
                    });
            } else {
                rootObject.$store.dispatch('setServerWSData', {});
                rootObject.$store.dispatch('setWsReloaded', true);
                rootObject.$store.dispatch('setWsConnectOpenStatus', false);
                localStorage.removeItem("jwt_token");
                rootObject.$store.dispatch('setUser', null);
                rootObject.$store.dispatch('changLoginStatus', false);
                rootObject.com_CheckVisitActivityAndTokenStatus(false);
                rootObject.$store.dispatch('setWsServerLoadConnection', {});
                if (param.redirect_url) {
                    rootObject.$router.push(param.redirect_url);
                }
            }
        },

        com_setPanelData(param) {
            var previousLoginStatus = this.$store.getters.getLoginStatus;
            this.$store.dispatch('setPanelData', param.panel_settings);
            let loginparam = {
                'jwt_token': localStorage.getItem("jwt_token"),
            };
            this.com_setLoginData(loginparam);
        },

        com_currentDateTime(format = '', time = '') {
            //format = dmy, time = no
            const monthShortNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            var current = new Date();

            if (format == 'dmy') {
                var date = ('0' + current.getDate()).slice(-2)
                    + ' '
                    + monthShortNames[(current.getMonth())]
                    + ', '
                    + current.getFullYear()
            } else {
                var date = current.getFullYear()
                    + '-'
                    + ('0' + (current.getMonth() + 1)).slice(-2)
                    + '-'
                    + ('0' + current.getDate()).slice(-2);
            }

            if (time == '' || time == 'yes') {
                var time = ('0' + current.getHours()).slice(-2)
                    + ":"
                    + ('0' + current.getMinutes()).slice(-2)
                    + ":"
                    + ('0' + current.getSeconds()).slice(-2);
            } else {
                time = '';
            }

            var dateTime = date + ' ' + time;
            return dateTime;
        },

        com_currentDateRange(addition_days = 0) {
            //Current Date
            const current = new Date();
            const startDate = current.getFullYear()
                + '-'
                + ('0' + (current.getMonth() + 1)).slice(-2)
                + '-'
                + ('0' + current.getDate()).slice(-2);

            //Last Date
            var lastDate = new Date();
            if (parseInt(addition_days) != 0) {
                lastDate.setDate(lastDate.getDate() + parseInt(addition_days));
            }

            const endDate = lastDate.getFullYear()
                + '-'
                + ('0' + (lastDate.getMonth() + 1)).slice(-2)
                + '-'
                + ('0' + lastDate.getDate()).slice(-2);

            if (endDate < startDate) {
                var dateArr = {startDate: endDate, endDate: startDate};
            } else {
                var dateArr = {startDate: startDate, endDate: endDate};
            }

            return dateArr;
        },

        com_modifyDateTime(no_of_days = 0, date = '') {
            if (date != "") {
                var newDate = new Date(date);
            } else {
                var newDate = new Date();
            }

            if (parseInt(no_of_days) != 0) {
                newDate.setDate(newDate.getDate() + parseInt(no_of_days));
            }

            var modifiedDate = newDate.getFullYear()
                + '-'
                + ('0' + (newDate.getMonth() + 1)).slice(-2)
                + '-'
                + ('0' + newDate.getDate()).slice(-2)
                + ' ' + ('0' + newDate.getHours()).slice(-2) + ':'
                + ('0' + newDate.getMinutes()).slice(-2) + ':'
                + ('0' + newDate.getSeconds()).slice(-2);
            return modifiedDate;
        },

        com_displayDateFormat(date) {
            var newDate = new Date(date);
            var resultArr = [];
            var convertedDate = newDate.getFullYear()
                + '-'
                + ('0' + (newDate.getMonth() + 1)).slice(-2)
                + '-'
                + ('0' + newDate.getDate()).slice(-2);

            var convertedHour = newDate.getHours();

            resultArr = {date: convertedDate, hour: convertedHour};
            return resultArr;
        },

        com_modifyAccountID(date, id) {
            var newDate = new Date(date);
            var convertedDate = newDate.getFullYear()
                + ('0' + (newDate.getMonth() + 1)).slice(-2)
                + ('0' + newDate.getDate()).slice(-2);
            return convertedDate+id;
        },

        com_pageNotFound() {
            this.$router.push('pagenotfound');
        },

        com_verifyAllowOrNotSetting() {
            return true;
        },

        com_removeHostedPinPrefix(actualPin, prefix, panel_type) {
            if (panel_type == 'hosted') {
                var newPin = actualPin.replace(prefix, "");
                var hostedPin = newPin;
                if (newPin.charAt(0) === '-') {
                    hostedPin = newPin.substring(1);
                }
                return hostedPin;
            } else {
                return actualPin;
            }
        },

        com_errorHandel(error) {
            if (error.response.status == 401) {
                let loginparam = {
                    'jwt_token': '',
                    'redirect_url': 'login',
                };
                this.com_setLoginData(loginparam);
            }
        },

        com_CheckVisitActivityAndTokenStatus(startInterval = true) {
            var rootObject = this;
            var intervalObj = null;

            // system will auto logout after this idle time
            var maxIdleTime = process.env.VUE_APP_MAXIDLETIME;

            //Check system activity after this interval
            var intervalTime = process.env.VUE_APP_CHECKACTIVITYINTERVALTIME;

            // after this tim system will go for refressh token
            var tokenRefreshTime = process.env.VUE_APP_TOKENREFRESHTIME;

            if (startInterval) {
                intervalObj = setInterval(function () {
                    var lastActivityTime = localStorage.getItem('lastActivityTime');
                    var lasttokenUpdateTime = localStorage.getItem('tkutim');
                    var current = new Date();
                    var totalIdleTime = parseInt(lastActivityTime) + parseInt(maxIdleTime);
                    //console.log("Hello");
                    //console.log(totalIdleTime +" _ "+current.getTime());
                    if (totalIdleTime < current.getTime()) {
                        //This is Logout
                        intervalObj = localStorage.getItem("visitActvityProcess");
                        clearInterval(intervalObj);
                        let loginparam = {
                            'jwt_token': '',
                            'redirect_url': 'login',
                        };
                        rootObject.com_setLoginData(loginparam);
                    } else {
                        var nextTokenRefreshTime = parseInt(lasttokenUpdateTime) + parseInt(tokenRefreshTime);
                        if (current.getTime() >= nextTokenRefreshTime) {
                            rootObject.com_RefreshToken();
                        }
                    }
                }, intervalTime);
                localStorage.setItem('visitActvityProcess', intervalObj);
            } else {
                //Clear Interval
                intervalObj = localStorage.getItem("visitActvityProcess");
                if (intervalObj) {
                    clearInterval(intervalObj);
                    localStorage.removeItem("visitActvityProcess");
                }
            }
        },

        com_SetLastActivityTime() {
            var current = new Date();
            localStorage.setItem('lastActivityTime', current.getTime());
        },

        com_UpdateTokenAndTime(token) {
            var current = new Date();
            localStorage.removeItem("jwt_token");
            localStorage.removeItem("tkutim");
            localStorage.setItem("jwt_token", token);
            localStorage.setItem("tkutim", current.getTime());
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            //console.log("token updated");
        },

        com_RefreshToken() {
            var rootObject = this;
            axios.get('refresh-token').then(function (response) {
                rootObject.com_UpdateTokenAndTime(response.data.token);
            })
                .catch(function (error) {
                    let alertmessage = "";
                    for (var prop in error.response.data) {
                        alertmessage += ' ' + error.response.data[prop]
                    }
                    console.log(alertmessage);
                    rootObject.com_errorHandel(error);
                });
        },
        ws_data(serverArray) {
            var rootObject = this;
            var dataArray = [];

            for (var s = 0; s < serverArray.length; s++) {
                try {
                    this.connection = new WebSocket('ws://' + serverArray[s] + ':9000/');
                    rootObject.connection.onmessage = function (event) {
                        var websocketData = (JSON.parse(event.data));
                        var serverId = websocketData.Payload.server_id;
                        dataArray[serverId] = websocketData.Payload;


                        var oldwsData = rootObject.$store.getters.serverWSData;
                        if (oldwsData == undefined) {
                            dataArray[serverId] = websocketData.Payload;
                            rootObject.$store.dispatch('setServerWSData', dataArray);
                        } else {
                            oldwsData[serverId] = websocketData.Payload;
                            rootObject.$store.dispatch('setServerWSData', oldwsData);
                        }
                        console.log(Date.now() + '_' + serverId);
                        //rootObject.$store.dispatch('setServerWSData', dataArray);
                    };
                } catch (e) {
                    console.log(e);
                }

            }

            rootObject.$store.dispatch('setServerWSData', dataArray);
        },
        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'

            var k = 1024
            var dm = decimals < 0 ? 0 : decimals
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            var i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        }


    }
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

