<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>{{ LabelFormName }} Server</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">{{ LabelFormName }} Server</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Server Name
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="name"
                      id="name"
                      class="form-control input-sm"
                      v-model="name"
                      value=""
                      placeholder="name"
                      type="text"
                    />
                    <label v-if="error_name" class="error" for="name">{{
                      error_name
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >IP <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="ip"
                      v-model="ip"
                      id="ip"
                      class="form-control input-sm"
                      value=""
                      placeholder="Server IP"
                      type="text"
                    />
                    <label v-if="error_ip" class="error" for="name">{{
                      error_ip
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Capacity <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="capacity"
                      id="capacity"
                      v-model="capacity"
                      class="form-control input-sm"
                      placeholder="Server load Capacity"
                      type="number"
                    />
                    <label v-if="error_capacity" class="error" for="name">{{
                      error_capacity
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Bandwidth Capacity
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="bandwidth_capacity"
                      id="bandwidth_capacity"
                      v-model="bandwidth_capacity"
                      class="form-control input-sm"
                      placeholder="Server Bandwidth Capacity"
                      type="number"
                    />
                    <label
                      v-if="error_bandwidth_capacity"
                      class="error"
                      for="name"
                      >{{ error_bandwidth_capacity }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Bandwidth Unit</label>
                  <div class="col-md-8">
                    <select
                      name="bandwidth_capacity_unit"
                      id="bandwidth_capacity_unit"
                      class="form-control input-sm"
                      v-model="bandwidth_capacity_unit"
                    >
                      <option value="bps">bps</option>
                      <option value="kbps">kbps</option>
                      <option value="Mbps">Mbps</option>
                      <option value="Gbps">Gbps</option>
                      <option value="Tbps">Tbps</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Order Priority</label>
                  <div class="col-md-8">
                    <input
                      name="order_priority"
                      id="order_priority"
                      v-model="order_priority"
                      class="form-control input-sm"
                      placeholder="Order Priority"
                      type="number"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Location(City)
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <multiselect
                      v-model="value"
                      :options="cityLists"
                      label="label"
                      track-by="id"
                      :multiple="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :show-no-results="true"
                      :hide-selected="false"
                    >
                      <!-- affichage du multiselect plié -->
                      <template slot="singleLabel" slot-scope="{ option }">
                        <strong>{{ option.label }}</strong>
                        <span style="color: red">&nbsp;{{ option.city }}</span>
                      </template>
                      <!-- affichage des options(multiselect déplié) -->
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <span class="option__title">
                            <strong>{{ option.label }}&nbsp;</strong>
                          </span>
                          <span class="option__small" style="color: green">{{
                            option.city
                          }}</span>
                        </div>
                      </template>
                    </multiselect>
                    <label v-if="error_cityLists" class="error" for="name">{{
                      error_cityLists
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Upload WireGuard Config?</label
                  >
                  <div class="col-md-2">
                    <input
                      name="has_wireguard_config"
                      type="checkbox"
                      v-model="has_wireguard_config"
                      id="has_wireguard_config"
                    />
                  </div>
                  <label class="col-md-4 control-label" v-if="isEditing"
                    >Remove Old Config?</label
                  >
                  <div class="col-md-2" v-if="isEditing">
                    <input
                      name="remove_old_config"
                      type="checkbox"
                      v-model="remove_old_config"
                      id="remove_old_config"
                    />
                  </div>
                </div>
                <div class="form-group" v-if="has_wireguard_config">
                  <label class="col-md-4 control-label">File</label>
                  <div class="col-md-8">
                    <input
                      class="form-control input-sm"
                      name="wireguard_config"
                      id="wireguard_config"
                      type="file"
                      @change="onFileSelected"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Active</label>
                  <div class="col-md-8">
                    <input
                      name="status"
                      type="checkbox"
                      v-model="status"
                      id="status"
                      checked="checked"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  name: "AddServers",
  components: {
    Multiselect,
  },
  data() {
    return {
      value: null,
      cityLists: {},
      id: "",
      name: "",
      ip: "",
      path: "",
      capacity: "",
      bandwidth_capacity: "",
      bandwidth_capacity_unit: "Mbps",
      order_priority: 1,
      status: true,
      has_wireguard_config: false,
      remove_old_config: false,
      wireguard_file: null,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Add New",
      error_name: false,
      error_ip: false,
      error_capacity: false,
      error_bandwidth_capacity: false,
      error_cityLists: false,
    };
  },
  mounted: function () {
    $(".select2").select2();
  },
  created() {
    loader.show();
    var rootObject = this;
    axios
      .get("get-cities")
      .then(function (response) {
        rootObject.cityLists = response.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });

    if (this.$route.params.id) {
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    labelWithCity({ label, city }) {
      return `${label} — [${city}]`;
    },
    getEditData() {
      loader.show();
      let rootObject = this;
      rootObject.LabelFormName = "Edit";
      axios
        .get("vpn-servers/" + rootObject.id)
        .then(function (response) {
          rootObject.name = response.data.name;
          rootObject.ip = response.data.ip;
          rootObject.path = response.data.path;
          rootObject.capacity = response.data.capacity;
          rootObject.order_priority = response.data.order_priority;
          rootObject.bandwidth_capacity = response.data.bandwidth_capacity;
          rootObject.bandwidth_capacity_unit =
            response.data.bandwidth_capacity_unit;
          rootObject.value = {
            id: response.data.city_id,
            label: response.data.city,
            city: response.data.country,
          };
          if (response.data.status != 1) {
            rootObject.status = false;
          }
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      console.log(this.value);
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_name = false;
          rootObject.error_ip = false;
          rootObject.error_capacity = false;
          rootObject.error_bandwidth_capacity = false;
          rootObject.error_cityLists = false;
        }, 5000);
        return 0;
      }

      if (this.status === true) {
        var status = 1;
      } else {
        var status = 2;
      }
      loader.show();
      let querystr = "";
      const form = new FormData();
      form.append("name", this.name);
      form.append("ip", this.ip);
      form.append("city_id", this.value.id);
      form.append("path", this.path);
      form.append("capacity", this.capacity);
      form.append("order_priority", this.order_priority);
      form.append("bandwidth_capacity", this.bandwidth_capacity);
      form.append("bandwidth_capacity_unit", this.bandwidth_capacity_unit);
      form.append("status", status);
      if (rootObject.has_wireguard_config) {
        form.append("wireguard_config", this.wireguard_file);
      }
      if (rootObject.isEditing) {
        form.append("_method", "put");
        if (this.remove_old_config) {
          form.append("remove_old_config", true);
        } else {
          form.append("remove_old_config", false);
        }

        querystr = "/" + rootObject.id;
      }
      //console.log(querystr)

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("vpn-servers" + querystr, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          if (!rootObject.isEditing) {
            rootObject.name = "";
            rootObject.ip = "";
            rootObject.path = "";
            rootObject.capacity = "";
            rootObject.order_priority = "";
            rootObject.bandwidth_capacity = "";
            rootObject.bandwidth_capacity_unit = "Mbps";
          }
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    onFileSelected(event) {
      //console.log(event.target.files[0]);
      this.wireguard_file = event.target.files[0];
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.name) {
        this.error_name = "This field is required";
        has_error = true;
      }

      if (!this.ip) {
        this.error_ip = "This field is required";
        has_error = true;
      }

      if (!this.capacity) {
        this.error_capacity = "This field is required";
        has_error = true;
      }

      if (!this.bandwidth_capacity) {
        this.error_bandwidth_capacity = "This field is required";
        has_error = true;
      }

      if (!this.value) {
        this.error_cityLists = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.multiselect__select {
  width: 25px !important;
  height: 25px !important;
  padding: 6px 8px !important;
}
.multiselect__tags {
  min-height: 0;
  padding: 0;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
