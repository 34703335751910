<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Update Your Profile</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-sm-7">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Basic Informations</h3>
            </div>
            <div class="box-body form-box-body form-horizontal">
              <div class="form-group">
                <div>
                  <label class="col-md-4 control-label"
                    >Email <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="email"
                      id="email"
                      class="form-control input-sm"
                      value=""
                      v-model="email"
                      placeholder="Change Email"
                      type="text"
                    />
                    <label v-if="error_email" class="error" for="email">{{
                      error_email
                    }}</label>
                    <label class="field_suggestion"> </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div>
                  <label class="col-md-4 control-label"
                    >Login Username
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="username"
                      id="username"
                      class="form-control input-sm"
                      value=""
                      v-model="username"
                      placeholder="Change Username"
                      type="text"
                    />
                    <label v-if="error_username" class="error" for="username">{{
                      error_username
                    }}</label>
                    <label class="field_suggestion"> </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div>
                  <label class="col-md-4 control-label"
                    >Change Password ?
                  </label>
                  <div class="col-md-8">
                    <input
                      name="change_password"
                      type="checkbox"
                      v-model="change_password"
                      id="change_password"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group passwordBlock" v-if="change_password">
                <div>
                  <label class="col-md-4 control-label"
                    >Old Password
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="old_password"
                      id="old_password"
                      class="form-control input-sm"
                      value=""
                      v-model="old_password"
                      placeholder="Enter Password"
                      type="text"
                    />
                    <label
                      v-if="error_old_password"
                      class="error"
                      for="old_password"
                      >{{ error_old_password }}</label
                    >
                    <label class="field_suggestion"> </label>
                  </div>
                </div>
              </div>

              <div class="form-group passwordBlock" v-if="change_password">
                <label class="col-md-4 control-label">
                  New Password
                  <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <input
                    name="new_password"
                    id="new_password"
                    class="form-control input-sm stop-negative-value"
                    v-model="new_password"
                    placeholder="New Password"
                    type="text"
                  />
                  <label
                    v-if="error_new_password"
                    class="error"
                    for="new_password"
                    >{{ error_new_password }}</label
                  >
                  <label class="field_suggestion"> </label>
                </div>
              </div>

              <div class="form-group passwordBlock" v-if="change_password">
                <label class="col-md-4 control-label"
                  >Re-Type New Password
                  <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <input
                    name="retype_new_password"
                    id="retype_new_password"
                    class="form-control input-sm stop-negative-value"
                    v-model="retype_new_password"
                    placeholder="Re-Type New Password"
                    type="text"
                  />
                  <label
                    v-if="error_retype_new_password"
                    class="error"
                    for="retype_new_password"
                    >{{ error_retype_new_password }}</label
                  >
                  <label class="field_suggestion"> </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 btnCenter">
          <input
            type="submit"
            class="btn btn-sm btn-submit btn-submit-big"
            @click="formSubmit"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminChangePassword",
  data() {
    return {
      alert_message: "",
      userData: {},
      email: "",
      username: "",
      change_password: false,
      error_email: false,
      error_username: false,
      error_old_password: false,
      error_new_password: false,
      error_retype_new_password: false,
      old_password: "",
      new_password: "",
      retype_new_password: "",
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);
    this.username = this.userData.login;
    this.email = this.userData.email;
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        return false;
      }

      loader.show();
      const form = new FormData();
      form.append("email", this.email);
      form.append("username", this.username);
      if (this.change_password) {
        form.append("change_pass", "1");
        form.append("old_password", this.old_password);
        form.append("new_password", this.new_password);
        form.append("retype_new_password", this.retype_new_password);
      }

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("/change-admin-profile-info", form, config)
        .then(function (response) {
          rootObject.alert_message = response.data.message;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          loader.hide();
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });

      setTimeout(function () {
        rootObject.alert_message = "";
        rootObject.error_old_password = false;
        rootObject.error_new_password = false;
        rootObject.error_retype_new_password = false;
      }, 5000);
    },
    fromValidation(reset = false) {
      var has_error = false;

      if (!this.email) {
        this.error_email = "This field is required";
        has_error = true;
      }
      if (!this.username) {
        this.error_username = "This field is required";
        has_error = true;
      }

      if (this.change_password) {
        if (!this.old_password) {
          this.error_old_password = "This field is required";
          has_error = true;
        }
        if (!this.new_password) {
          this.error_new_password = "This field is required";
          has_error = true;
        }
        if (!this.retype_new_password) {
          this.error_retype_new_password = "This field is required";
          has_error = true;
        }

        if (this.new_password != this.retype_new_password) {
          this.error_retype_new_password =
            "Confirm password must not be different!";
          has_error = true;
        }

        if (this.old_password === this.retype_new_password) {
          this.error_retype_new_password =
            "Old Password and New Password Must not be same!";
          has_error = true;
        }
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
