<template>
    <AdminChangePassword />
</template>

<script>

// @ is an alias to /src
import AdminChangePassword from "@/components/AdminChangePassword.vue";

export default {
name: "AdminProfile",
components: {
    AdminChangePassword,
},
};
</script>