<template>
  <div id="app" @mousedown="com_SetLastActivityTime">
    <AppHeader v-if="loginStatus"/>
    <LeftSideBar v-if="loginStatus"/>
    <router-view />
    <AppFooter v-if="loginStatus"/>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import LeftSideBar from "@/components/LeftSideBar.vue";
import AppFooter from "@/components/AppFooter.vue";
import {mapGetters} from 'vuex' 
import axios from 'axios'

export default {
  components: {
    AppHeader,
    LeftSideBar,
    AppFooter,
  },
  created(){
    this.$store.dispatch('setWsConnectOpenStatus', false);
    this.$store.dispatch('setServerWSData', {});

    var api_public_path = process.env.VUE_APP_APIADMINPUBLIC;
    var rootObject = this;

    //Get Panel Configurations
    axios.get('panel-setting')
    .then(function (response) { 
      if(response.data.settings == null){
        rootObject.com_pageNotFound();
      }else{
        let panelparam = {
          'panel_settings':response.data.settings,
        };
        window.document.title = "Admin Panel - " + (response.data.settings.com_name).toUpperCase();
        if(response.data.settings.favicon){
          $("#favicon").attr('href', api_public_path+'uploads/images/'+response.data.settings.favicon);
        }else{
          $("#favicon").attr('href', '/assets/img/symlex_logo.png');
        }
        rootObject.com_setPanelData(panelparam);
      }
    })
    .catch(function (error) {
      /*
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += ' '+error.response.data[prop]
        }
        console.log(alertmessage);
      */
      rootObject.com_pageNotFound();
    });
    

  },
  methods: {
    
  },
  computed: {
    ...mapGetters({
      loginStatus : 'getLoginStatus'
    })
  },
  methods: {

  }
};
</script>

<style>
  .content-wrapper{
    min-height: 97.2vh !important;
    max-height: 100% !important;
  }
</style>
