import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PinLists from "../views/PinList.vue";
import loginView from "../views/Login.vue";
import AddSinglePins from "../views/AddSinglePin.vue";
import EditSubscriber from "../views/EditSinglePin.vue";
import AddRatePlans from "../views/AddRatePlan.vue";
import RatePlans from "../views/RatePlan.vue";
import EditRatePlans from "../views/EditRatePlan.vue";
import SaleingRatePlans from "../views/SaleingRatePlan.vue";
import AddSaleRate from "../views/AddSaleRate.vue";
import EditSaleRates from "../views/EditSaleRate.vue";

//IP List
import IpLists from "../views/IpLists/IpList";
import AddIps from "../views/IpLists/AddIp";
import EditIps from "../views/IpLists/EditIp";

//IP Bundle
import IpBundleLists from "../views/IpBundle/IpBundleList";
import IpBundlAdds from "../views/IpBundle/IpBundleAdd";
import IpBundlEdits from "../views/IpBundle/IpBundleEdit";
import IpBundleDtlsLists from "../views/IpBundle/IpBundleDtlsList";

//vpn servers
import Addservers from "../views/Addservers.vue";
import ServerLists from "../views/ServerLists.vue";
import EditServers from "../views/EditServer.vue";
import InstallOvpn from "../views/InstallOvpn.vue";

//Radius Auth Server
// import AddRadiusAuth from "../views/AddRadiusAuth.vue";
// import RadiusAuth from "../views/RadiusAuth.vue";
// import EditRadiusAuth from "../views/EditRadiusAuth.vue";
// import InstallAuth from "../views/InstallAuth.vue";

import ResellerConnectedUsers from "../views/ResellerConnectedUser.vue";
import AddPanelSettings from "../views/AddPanelSetting.vue";
import AddConfigSettings from "../views/AddConfigSetting.vue";
import PinSubscriptionReportView from "../views/reports/PinSubscriptionReport.vue";
import passwordResetView from "../views/PasswordReset.vue";

import AdminProfile from "../views/AdminProfile.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "loginView" },
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "loginView",
    component: loginView,
  },
  {
    path: "/subscriberslist",
    name: "PinLists",
    component: PinLists,
    meta: { requiresAuth: true },
  },
  {
    path: "/addsinglesubscriber",
    name: "AddSinglePins",
    component: AddSinglePins,
    meta: { requiresAuth: true },
  },
  {
    path: "/editsubscriber/:id",
    name: "EditSubscriber",
    component: EditSubscriber,
    meta: { requiresAuth: true },
  },
  {
    path: "/addrateplans",
    name: "AddRatePlans",
    component: AddRatePlans,
    meta: { requiresAuth: true },
  },
  {
    path: "/rateplans",
    name: "RatePlans",
    component: RatePlans,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit_rateplans/:id",
    name: "EditRatePlans",
    component: EditRatePlans,
    meta: { requiresAuth: true },
  },
  {
    path: "/rateplans/:id",
    name: "SaleingRatePlans",
    component: SaleingRatePlans,
    meta: { requiresAuth: true },
  },
  {
    path: "/addsalerate/:id",
    name: "AddSaleRate",
    component: AddSaleRate,
    meta: { requiresAuth: true },
  },
  {
    path: "/editsalerate/:id",
    name: "EditSaleRates",
    component: EditSaleRates,
    meta: { requiresAuth: true },
  },
  {
    path: "/iplists",
    name: "IpLists",
    component: IpLists,
    meta: { requiresAuth: true },
  },
  {
    path: "/addip",
    name: "AddIp",
    component: AddIps,
    meta: { requiresAuth: true },
  },
  {
    path: "/editip/:id",
    name: "EditIp",
    component: EditIps,
    meta: { requiresAuth: true },
  },
  {
    path: "/ipbundle",
    name: "IpBundleLists",
    component: IpBundleLists,
    meta: { requiresAuth: true },
  },
  {
    path: "/ipbundleadd",
    name: "IpBundleAdd",
    component: IpBundlAdds,
    meta: { requiresAuth: true },
  },
  {
    path: "/ipbundleedit/:id",
    name: "IpBundleEdit",
    component: IpBundlEdits,
    meta: { requiresAuth: true },
  },
  {
    path: "/ipbundledtlslist/:id",
    name: "IpBundleDtlsLists",
    component: IpBundleDtlsLists,
    meta: { requiresAuth: true },
  },
  {
    path: "/addnewserver",
    name: "Addservers",
    component: Addservers,
    meta: { requiresAuth: true },
  },
  {
    path: "/serverlists",
    name: "ServerLists",
    component: ServerLists,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit_server/:id",
    name: "EditServers",
    component: EditServers,
    meta: { requiresAuth: true },
  },
  {
    path: "/install_vpn_server/:id",
    name: "InstallOvpns",
    component: InstallOvpn,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/radius-auth-server",
  //   name: "RadiusAuthList",
  //   component: RadiusAuth,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/add-radius-auth-server",
  //   name: "AddRadiusAuth",
  //   component: AddRadiusAuth,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/edit-radius-auth-server/:id",
  //   name: "EditRadiusAuth",
  //   component: EditRadiusAuth,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/install_auth_server/:id",
  //   name: "InstallAuth",
  //   component: InstallAuth,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/resellerconnectedusers",
    name: "ResellerConnectedUsers",
    component: ResellerConnectedUsers,
    meta: { requiresAuth: true },
  },
  {
    path: "/panelsettings",
    name: "AddPanelSettings",
    component: AddPanelSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "/configsettings",
    name: "AddConfigSettings",
    component: AddConfigSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "/pinsubscriptionreport",
    name: "PinSubscriptionReportView",
    component: PinSubscriptionReportView,
    meta: { requiresAuth: true },
  },

  {
    path: "/updateadminprofile",
    name: "AdminProfile",
    component: AdminProfile,
    meta: { requiresAuth: true },
  },

  {
    path: "/pagenotfound",
    name: "pagenotfound",
    component: () => import("../components/404NotFound.vue"),
  },
  {
    path: "/pagenotfound",
    name: "pagesnotfound",
    component: () => import("../components/404NotFound.vue"),
  },
  {
    path: "/logout",
    redirect: { name: "loginView" },
  },
  {
    path: "/passwordReset",
    name: "passwordReset",
    component: passwordResetView,
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt_token") == null) {
      next({
        name: "loginView",
      });
    } else {
      next();
    }
  } else {
    if (to.name == "loginView") {
      if (localStorage.getItem("jwt_token") == null) {
        next();
      } else {
        next({
          name: "Home",
        });
      }
    } else {
      next();
    }
  }
});

export default router;
