import { render, staticRenderFns } from "./AdminChangePassword.vue?vue&type=template&id=00b06f17&scoped=true"
import script from "./AdminChangePassword.vue?vue&type=script&lang=js"
export * from "./AdminChangePassword.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00b06f17",
  null
  
)

export default component.exports